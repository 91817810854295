













import {Component, Prop, Vue} from 'vue-property-decorator'
import config from "@/config";

@Component
export default class AvatarUploader extends Vue {
  @Prop() img!: string
  action = `${config.baseURL}/adminapi/upload/lightFile`;
  version = config.version;

  handleAvatarSuccess(response: any) {
    const {res: {res: {requestUrls}}} = response;
    this.$emit('on-success', requestUrls[0].split('?')[0])
  }

  beforeAvatarUpload(file: any) {
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!');
      return false
    }
  }


  async uploadFile(file: any) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const OSS = require('ali-oss')
    const client = new OSS({
      accessKeyId: 'LTAI5t6CTbjsxAa1kz4x3RCp',
      accessKeySecret: 'eNlsZparfM5VkW3OSQJ9MkbH0UJH8D',
      // stsToken: '',
      region: 'oss-cn-qingdao',
      bucket: 'imagetru3d'
    })
    // 获取要上传的文件
    // 获取扩展名
    const suffix = file.file.name.split('.').pop()
    const uuid = new Date().getTime() + Math.random().toString(36).substr(2)
    const path = 'uploads/light/soft_' + uuid + '.' + suffix
    const res = await client.multipartUpload(path, file.file)
    return {
      res,
      file
    }
  }
}
