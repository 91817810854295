






























































import BtnUpload from '@/components/upload/BtnUpload.vue'
import AvatarUploader from '@/components/upload/AvatarUploader.vue'
import {Component, Vue} from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import config from '@/config'
import {apiAddSoftware, apiEditSoftwareInfoById, apiGetSoftwareInfoById} from '@/api/setting/plugins'

@Component({
  components: {
    MaterialSelect,
    BtnUpload,
    AvatarUploader
  }
})
export default class DevicePluginEdit extends Vue {
  $refs!: { form: any, uploadSoft: any }
  id!: any
  loading = false
  form = {
    soft_name: '', // 软件名称
    soft_status: 0, // 是否允许下载 1:允许下载,0禁止下载
    sort: '',
    soft_version: 1,
    // is_update: 1,
    soft_url: '', // 图片地址
    content: '', // 描述
    is_update: 0
  }

  rules = {
    soft_name: [
      {
        required: true,
        message: '请输入软件名称',
        trigger: ['blur', 'change']
      }
    ],
    soft_url: [
      {
        required: true,
        message: '请上传封面图',
        trigger: ['blur', 'change']
      }
    ],
    content:
        [
          {
            required: true,
            message: '请填写描述',
            trigger: ['blur', 'change']
          }
        ]
  }

// S 上传插件

// token
  token = this.$store.getters.token
// 版本号
  version = config.version

// 上传插件接口
  action = `${config.baseURL}/adminapi/upload/lightFile`

// 文件上传成功时的回调
  handleSuccess(response: any) {
    this.form.soft_url = response
  }


// // 设备插件详情
//   getPluginDetail() {
//     this.loading = true
//     apiPluginDetail({id: this.id}).then((res: any) => {
//       this.form = res
//       this.loading = false
//     })
//   }

// 新增设备类型
  handleSave() {
    this.$refs.form.validate((valid: boolean) => {
      if (valid) {
        // 判断是编辑还是新增
        const api = this.id
            ? apiEditSoftwareInfoById(this.form)
            : apiAddSoftware(this.form)
        // 新增或编辑后，返回上一界面
        api.then(() => {
          this.$message.success('保存成功！')
          this.$router.go(-1)
        })
      } else {
        return false
      }
    })
  }

  created() {
    this.id = this.$route.query.id
    if (this.id) {
      apiGetSoftwareInfoById(this.id).then((res) => {
        this.form = res
      })
    }

  }
}
