import request from '@/plugins/axios'
// 新增软件
export const apiAddSoftware = (data: any) =>
    request.post('/soft.soft/addSource', data)
// 获取软件详情
export const apiGetSoftwareInfoById = (id: number) =>
    request.get('/soft.soft/getSoftInfo', {params: {id}})
// 根据软件获取插件id
export const apiGetPluginsBySoftwareId = (params: any) =>
    request.get('/soft.soft/getPluginList', {params})
//编辑
export const apiEditSoftwareInfoById = (data: any) =>
    request.post('/soft.soft/editSource', data)
// 新增插件数据
export const apiAddPlugins = (data: any) =>
    request.post('/soft.soft/setPlugin', data)
// 修改插件数据
export const apiEditPlugin = (data: any) =>
    request.post('/soft.soft/savePlugin', data)
// 删除插件数据
export const apiDelPlugin = (id: any) =>
    request.get('/soft.soft/delPlugin', {params: {id}})
// 获取插件详情
export const apiGetPluginById = (id: number) =>
    request.get('/soft.soft/getPlugin', {params: {id}})
